import React from "react";
import Page from "./../../components/Page";
import Section from "./../../components/Section";
import SEO from "./../../components/SEO";

import Title, { SubTitle } from "../../components/releaseNotes/Title";
import Header from "../../components/releaseNotes/Header";
import Feature from "../../components/releaseNotes/Feature";
import VideoView from "../../components/releaseNotes/VideoView";
import labelmapAssist from "../../images/3p10/labelmap-assist.gif";
import promptSegmentation from "../../images/3p10/prompt-segmentation.gif";
import oneClickSeg from "../../images/3p10/one-click-seg.gif";
import brushPreview from "../../images/3p10/brush-preview.gif";
import undoRedo from "../../images/3p10/Undo-redo.png";
import segmentStats from "../../images/3p10/segment-stats.png";
import interpolation from "../../images/3p10/interpolation.gif";
import newComponentLib from "../../images/3p10/new-component-lib.png";

import MarkdownRenderer from "../../components/MarkdownRenderer";

const ChangeLog = `
* docs: migration guides 3p10 ([PR#4937](https://github.com/OHIF/Viewers/pull/4937))
* update screenshopts for test ([PR#4934](https://github.com/OHIF/Viewers/pull/4934))
* ui(fix): small Create Report dialog fixes ([PR#4935](https://github.com/OHIF/Viewers/pull/4935))
* refactor(ui): Migrate components to ui-next  ([PR#4930](https://github.com/OHIF/Viewers/pull/4930))
* ui(components): CinePlayer updated for ui-next ([PR#4932](https://github.com/OHIF/Viewers/pull/4932))
* ui(colors): Adds neutral color variable and updates scrollbars  ([PR#4933](https://github.com/OHIF/Viewers/pull/4933))
* fix(OIDC): Use new access_token after refresh ([PR#4853](https://github.com/OHIF/Viewers/pull/4853))
* fix(segmentation): Add segment jump for new segments and make panels scrollable ([PR#4928](https://github.com/OHIF/Viewers/pull/4928))
* ui(components): Viewport components updated to ui-next with design updates ([PR#4886](https://github.com/OHIF/Viewers/pull/4886))
* fix: routerBasename setting ([PR#4891](https://github.com/OHIF/Viewers/pull/4891))
* fix(seg): multiframe SEG ([PR#4890](https://github.com/OHIF/Viewers/pull/4890))
* [OHI-1681] feat(fonts): bundle fonts in OHIF and dont use google cdn ([PR#4821](https://github.com/OHIF/Viewers/pull/4821))
* feat: allow saving to same SR series ([PR#4889](https://github.com/OHIF/Viewers/pull/4889))
* fix(videoViewport): Resolve Cine Player Faults on DisplaySet Update ([PR#4909](https://github.com/OHIF/Viewers/pull/4909))
* feat(image capture): Enhance SEG and RTSS Support with Dependency Updates and Bug Fixes ([PR#4924](https://github.com/OHIF/Viewers/pull/4924))
* feat: Automate tracking prompts for a less disruptive annotation workflow ([PR#4822](https://github.com/OHIF/Viewers/pull/4822))
* fix(seg): Enhance segmentation tools and UI, refactor code, and update dependencies ([PR#4915](https://github.com/OHIF/Viewers/pull/4915))
* feat(segmentation): Enhance Segmentation with New AI and Once Click Tools ([PR#4910](https://github.com/OHIF/Viewers/pull/4910))
* Fix for initialImageIndex mismatch issue for loading SR after disabling prompts ([PR#4732](https://github.com/OHIF/Viewers/pull/4732))
* ui(components): Adds InputNumber component and 4d panel updates ([PR#4874](https://github.com/OHIF/Viewers/pull/4874))
* Update Test/tmtv for v3.10.0-beta.136 ([PR#4893](https://github.com/OHIF/Viewers/pull/4893))
* Fix : Resize was broken if 3d viewport displayed in HP ([PR#4561](https://github.com/OHIF/Viewers/pull/4561))
* Execute HP onProtocolEnter callback after HPservice.run( ([PR#4589](https://github.com/OHIF/Viewers/pull/4589))
* fix jump to measurement ([PR#4867](https://github.com/OHIF/Viewers/pull/4867))
* Revert "fix(measurement): fix for measurement jump is not switching series in 2DMPR (#4779)" ([PR#4898](https://github.com/OHIF/Viewers/pull/4898))
* fix(measurement): fix for measurement jump is not switching series in 2DMPR ([PR#4779](https://github.com/OHIF/Viewers/pull/4779))
* refactor: remove duplicated deleteMeasurements code ([PR#4895](https://github.com/OHIF/Viewers/pull/4895))
* fix: datasource connection error style ([PR#4888](https://github.com/OHIF/Viewers/pull/4888))
* fix orthanc seg ([PR#4883](https://github.com/OHIF/Viewers/pull/4883))
* fix: Skip showing NaN for average pixel/max value ([PR#4884](https://github.com/OHIF/Viewers/pull/4884))
* fix: Delete study measurements instead of all measurements ([PR#4882](https://github.com/OHIF/Viewers/pull/4882))
* fix: compare series date time ([PR#4887](https://github.com/OHIF/Viewers/pull/4887))
* ui(panels): Tool option components and responsive behavior for ROIThreshold ([PR#4876](https://github.com/OHIF/Viewers/pull/4876))
* feat: add customization to PanelStudyBrowser dbl click callback ([PR#4871](https://github.com/OHIF/Viewers/pull/4871))
* feat: Group findings table values with custom components/grouping functions ([PR#4712](https://github.com/OHIF/Viewers/pull/4712))
* feat(segmentation): Enhance Segmentation Tools with Preview and Selection Features ([PR#4870](https://github.com/OHIF/Viewers/pull/4870))
* fix(DicomWebDataSource) : Fix a missing null check in addRetrieveBulkDataNaturalized ([PR#4866](https://github.com/OHIF/Viewers/pull/4866))
* feat: overlapping segments ([PR#4849](https://github.com/OHIF/Viewers/pull/4849))
* feat: add back dicom tag browser SQ items ([PR#4869](https://github.com/OHIF/Viewers/pull/4869))
* ui(panels): PetSUV updates for ui-next and responsive layout ([PR#4864](https://github.com/OHIF/Viewers/pull/4864))
* feat(segmentation): segment statistics, labelmap interpolation and segment bidirectional ([PR#4865](https://github.com/OHIF/Viewers/pull/4865))
* ui(components): LayoutSelector recreated for ui-next ([PR#4845](https://github.com/OHIF/Viewers/pull/4845))
* fix(metadata): change the default values for cosines ([PR#4863](https://github.com/OHIF/Viewers/pull/4863))
* ui(migrate) Migrate local view to new ui-next components ([PR#4850](https://github.com/OHIF/Viewers/pull/4850))
* ui(components): Adds InputFilter component to ui-next ([PR#4848](https://github.com/OHIF/Viewers/pull/4848))
* fix: max call stack exceeded for dicom tag browser ([PR#4855](https://github.com/OHIF/Viewers/pull/4855))
* feat: update vtk js ([PR#4858](https://github.com/OHIF/Viewers/pull/4858))
* feat(viewport-gap): remove viewport gap and update border behavior ([PR#4835](https://github.com/OHIF/Viewers/pull/4835))
* fix(seg): sphere scissor on stack and cpu rendering reset properties was broken ([PR#4721](https://github.com/OHIF/Viewers/pull/4721))
* feat(customization): new customization service api ([PR#4688](https://github.com/OHIF/Viewers/pull/4688))
* fix(docs): image in customization ([PR#4735](https://github.com/OHIF/Viewers/pull/4735))
* fix/publish dependency ([PR#4753](https://github.com/OHIF/Viewers/pull/4753))
* fix(core): Address 3D reconstruction and Android compatibility issues and clean up 4D data mode ([PR#4762](https://github.com/OHIF/Viewers/pull/4762))
* fix: depandabot ([PR#4786](https://github.com/OHIF/Viewers/pull/4786))
* feat(hotkeys): Migrate hotkeys to customization service and fix issues with overrides ([PR#4777](https://github.com/OHIF/Viewers/pull/4777))
* fix(sr): sr hydration and load was not working, Screenshot Comparison, and Testing ([PR#4814](https://github.com/OHIF/Viewers/pull/4814))
* feat(toolbox): Refactor Toolbar and Toolbox to enable sections ([PR#4825](https://github.com/OHIF/Viewers/pull/4825))
* fix(rotation): support cycling rotateViewportCCW ([PR#4533](https://github.com/OHIF/Viewers/pull/4533))
* fix(tmtv tools): add CircleROIStartEndThreshold to supported tools in TMTV extension ([PR#4828](https://github.com/OHIF/Viewers/pull/4828))
* fix: dicom tag browser row height on toggle ([PR#4832](https://github.com/OHIF/Viewers/pull/4832))
* ui(components): Dialog and Modal components for ui-next ([PR#4772](https://github.com/OHIF/Viewers/pull/4772))
* fix: Cornerstone3D 3.0 ([PR#4816](https://github.com/OHIF/Viewers/pull/4816))
* fix: Performance of panel study browser ([PR#4819](https://github.com/OHIF/Viewers/pull/4819))
* separate checks for addWindowLevelActionMenu and addSegmentationOverlay ([PR#4813](https://github.com/OHIF/Viewers/pull/4813))
* Fix broken activateViewportBeforeInteraction behavior ([PR#4810](https://github.com/OHIF/Viewers/pull/4810))
* feat(Segments Looping) : Fixed looping through Segments of SEG ([PR#4793](https://github.com/OHIF/Viewers/pull/4793))
* docs: Fix broken link to viewer internationalization docs ([PR#4808](https://github.com/OHIF/Viewers/pull/4808))
* fix: Changes to address hang/crash on jump to instance ([PR#4679](https://github.com/OHIF/Viewers/pull/4679))
* fix(rt): jump to segment discards the configured width ([PR#4799](https://github.com/OHIF/Viewers/pull/4799))
* fix: Delay for all series thumbnails on fetching thumbnail ([PR#4802](https://github.com/OHIF/Viewers/pull/4802))
* ui(icons): Added New Segmentation Tool Art ([PR#4797](https://github.com/OHIF/Viewers/pull/4797))
* fix: combine frame instance ([PR#4792](https://github.com/OHIF/Viewers/pull/4792))
* fix: icon is not defined ([PR#4794](https://github.com/OHIF/Viewers/pull/4794))
* fix(Segments Looping) : Fixed looping through Segments of RTSTRUCT using Left/Right viewport buttons ([PR#4783](https://github.com/OHIF/Viewers/pull/4783))
* Fix button (ui-next) -> className ([PR#4604](https://github.com/OHIF/Viewers/pull/4604))
* fix: cache thumbnail in display set ([PR#4782](https://github.com/OHIF/Viewers/pull/4782))
* fix: lodash dependencies ([PR#4791](https://github.com/OHIF/Viewers/pull/4791))
* feat: improve dicom tag browser ([PR#4451](https://github.com/OHIF/Viewers/pull/4451))
* fix: right panel for the create mode cli command ([PR#4788](https://github.com/OHIF/Viewers/pull/4788))
* docs: fix yarn version ([PR#4790](https://github.com/OHIF/Viewers/pull/4790))
* fix(Frame View) : Fixed synchronization for Frame View Hanging Protocol  ([PR#4780](https://github.com/OHIF/Viewers/pull/4780))
* Feat/customization option for viewport notification ([PR#4638](https://github.com/OHIF/Viewers/pull/4638))
* feat: add viewport overlays to microscopy mode ([PR#4776](https://github.com/OHIF/Viewers/pull/4776))
* fix: add commandsManager to MoreDropdownMenu onClick props ([PR#4765](https://github.com/OHIF/Viewers/pull/4765))
* BUG: Fix changing colormap for second volume in fusion viewport ([PR#4746](https://github.com/OHIF/Viewers/pull/4746))
* feat: Add support for Custom Modal component in Modal Service ([PR#4752](https://github.com/OHIF/Viewers/pull/4752))
* fix(context-menu):  Fixing regression introduced by PR #4727 ([PR#4760](https://github.com/OHIF/Viewers/pull/4760))
* fix(measurement label auto-completion): Customization of measurement label auto-completion fails for measurements following arrow annotations. ([PR#4739](https://github.com/OHIF/Viewers/pull/4739))
* [OHI-1445] fix(store-segmentation): storing segmentations was hitting the wrong command resulting in an undefined datasource ([PR#4755](https://github.com/OHIF/Viewers/pull/4755))
* feat: Add customization support for UI components  ([PR#4634](https://github.com/OHIF/Viewers/pull/4634))
* feat: Remove non-functional Tailwind class for SegmentationPanel ([PR#4745](https://github.com/OHIF/Viewers/pull/4745))
* typo in pet_series_module ([PR#4748](https://github.com/OHIF/Viewers/pull/4748))
* ui(components): migrates to new toolbar ui components and Numeric for Input InputRange and InputDoubleRange ([PR#4728](https://github.com/OHIF/Viewers/pull/4728))
* Feat/custom onDropHandler for viewportGrid ([PR#4641](https://github.com/OHIF/Viewers/pull/4641))
* fix(dependencies): Update dcmjs library and improve documentation links ([PR#4741](https://github.com/OHIF/Viewers/pull/4741))
* feat(side-panels): Added resize handle interactive feedback for side panels ([PR#4734](https://github.com/OHIF/Viewers/pull/4734))
* feat(windowLevelActionMenu) customization support for windowLevelActionMenu ([PR#4725](https://github.com/OHIF/Viewers/pull/4725))
* fix(context menu): Context menus for edge-proximate measurements are partially obscured. ([PR#4727](https://github.com/OHIF/Viewers/pull/4727))
* [OHI-1357] feat(static-wado): add support for case-insensitive searching ([PR#4603](https://github.com/OHIF/Viewers/pull/4603))
* feat(panels): responsive thumbnails based on panel size  ([PR#4723](https://github.com/OHIF/Viewers/pull/4723))
* delete active annotation using backspace/delete key ([PR#4722](https://github.com/OHIF/Viewers/pull/4722))
* fix(ui): Update dependencies and add missing icons ([PR#4699](https://github.com/OHIF/Viewers/pull/4699))
* fix/multiframe stuff again ([PR#4693](https://github.com/OHIF/Viewers/pull/4693))
* fix: Having sop instance in a per-frame or shared attribute breaks load ([PR#4560](https://github.com/OHIF/Viewers/pull/4560))
* fix: bugs after multimonitor ([PR#4680](https://github.com/OHIF/Viewers/pull/4680))
* feat: Add simple multi-monitor support ([PR#4178](https://github.com/OHIF/Viewers/pull/4178))
* feat(dev): move to rsbuild for dev - faster ([PR#4674](https://github.com/OHIF/Viewers/pull/4674))
* fix(circleci): Update workflow dependencies for Docker publishing stages ([PR#4662](https://github.com/OHIF/Viewers/pull/4662))
* fix(docker): make multiarch work ([PR#4655](https://github.com/OHIF/Viewers/pull/4655))
* [OHI-798] fix(tools): enable additional tools in volume viewport ([PR#4620](https://github.com/OHIF/Viewers/pull/4620))
* [OHI-833] fix(datasource): attach auth headers for delete requests in the dicomweb datasource ([PR#4619](https://github.com/OHIF/Viewers/pull/4619))
* feat(icons): Add Clipboard icon and update MetadataProvider for null checks ([PR#4615](https://github.com/OHIF/Viewers/pull/4615))
* fix: Documentation and default enabled for bulkdata load ([PR#4607](https://github.com/OHIF/Viewers/pull/4607))
* feat: migrate icons to ui-next  ([PR#4606](https://github.com/OHIF/Viewers/pull/4606))
* feat(node): move to node 20 ([PR#4594](https://github.com/OHIF/Viewers/pull/4594))
* fix(sr): correct jump to first image via viewRef ([PR#4576](https://github.com/OHIF/Viewers/pull/4576))
* fix(CinePlayer): always show cine player for dynamic data ([PR#4575](https://github.com/OHIF/Viewers/pull/4575))
* fix(multiframe): metadata handling of NM studies and loading order  ([PR#4554](https://github.com/OHIF/Viewers/pull/4554))
* feat(segmentation): Enhance dropdown menu functionality in SegmentationTable ([PR#4553](https://github.com/OHIF/Viewers/pull/4553))
* fix(colorlut): use the correct colorlut index and update vtk ([PR#4544](https://github.com/OHIF/Viewers/pull/4544))
* [OHI-865] [OHI-646] fix(viewport): set a minimum width of 5px on viewports to prevent them from turning black/ going into an unrecoverable state. ([PR#4517](https://github.com/OHIF/Viewers/pull/4517))
* feat(CI): move tasks from yarn to bun, improve cypress tests ([PR#4476](https://github.com/OHIF/Viewers/pull/4476))
* fix/docs not maintained ([PR#4504](https://github.com/OHIF/Viewers/pull/4504))
* docs/version 1111 ([PR#4496](https://github.com/OHIF/Viewers/pull/4496))
* docs: update to next version [BUMP BETA] ([PR#4493](https://github.com/OHIF/Viewers/pull/4493))
* fix: Have an addIcon that adds to both ui and ui-next ([PR#4490](https://github.com/OHIF/Viewers/pull/4490))
* fix: Measurement Tracking: Various UI and functionality improvements ([PR#4481](https://github.com/OHIF/Viewers/pull/4481))
* feat(ui) updated component font sizes and design component docs ([PR#4486](https://github.com/OHIF/Viewers/pull/4486))
* fix(styles): several fixes for different styles ([PR#4483](https://github.com/OHIF/Viewers/pull/4483))
* fix: build ([PR#4471](https://github.com/OHIF/Viewers/pull/4471))
* OHIF with Cornerstone3D 2.0 ([PR#4347](https://github.com/OHIF/Viewers/pull/4347))
* fix(SR): Bring back the onModeEnter for the cornerstone-dicom-sr extension that was accidentally removed by PR #4586 ([PR#4616](https://github.com/OHIF/Viewers/pull/4616))
* fix(toolbars): Fix error when filtering out duplicate buttons for a button section. ([PR#4618](https://github.com/OHIF/Viewers/pull/4618))
* feat(resizable-side-panels): Make the left and right side panels (optionally) resizable. ([PR#4672](https://github.com/OHIF/Viewers/pull/4672))
* fix: Inconsistent Handling of Patient Name Tag ([PR#4703](https://github.com/OHIF/Viewers/pull/4703))
* fix: Display set should allow remembered updates ([PR#4707](https://github.com/OHIF/Viewers/pull/4707))
* docs: Documentation on how to set default configuration to search for today ([PR#4708](https://github.com/OHIF/Viewers/pull/4708))
* fix: context menu icon ([PR#4696](https://github.com/OHIF/Viewers/pull/4696))
* fix(seg): jump to the first slice in SEG and RT that has data ([PR#4605](https://github.com/OHIF/Viewers/pull/4605))
* [OHI-1483] fix(tag-browser): fix dicom tag browser not loading in segmentation mode in study panel ([PR#4601](https://github.com/OHIF/Viewers/pull/4601))
* fix: cs dicom sr commands module ([PR#4683](https://github.com/OHIF/Viewers/pull/4683))
* fix(header tools in voulme3D): All header tools are enabled in volume3D viewport ([PR#4677](https://github.com/OHIF/Viewers/pull/4677))
* migration docs typo (3.8 -> 3.9, useViewportGridStore()) ([PR#4675](https://github.com/OHIF/Viewers/pull/4675))
* feat: Start using group filtering to define measurements table layout ([PR#4501](https://github.com/OHIF/Viewers/pull/4501))
* [OHI-1547] fix(docker): run compression regardless of APP_CONFIG being present ( in cases such as volume mount) ([PR#4673](https://github.com/OHIF/Viewers/pull/4673))
* [OHI-1538] fix(icons): icons missing for volume presets and others ([PR#4671](https://github.com/OHIF/Viewers/pull/4671))
* fix: orthanc datasource dev ([PR#4663](https://github.com/OHIF/Viewers/pull/4663))
* Make StudyInstanceUID optional to retrieve a Series from DicomMetadataStore  ([PR#4644](https://github.com/OHIF/Viewers/pull/4644))
* fix: Inconsistencies and update the style setting on load for embedded styles from codingValues ([PR#4599](https://github.com/OHIF/Viewers/pull/4599))
* fix(circleci): Update workflow dependencies for Docker publishing stages ([PR#4661](https://github.com/OHIF/Viewers/pull/4661))
* fix(circleci): Update workflow dependencies for Docker publishing stages ([PR#4660](https://github.com/OHIF/Viewers/pull/4660))
* fix(Dockerfile): Clear bun package manager cache before installation ([PR#4659](https://github.com/OHIF/Viewers/pull/4659))
* ui(components): Viewport components added to ui-next ([PR#4649](https://github.com/OHIF/Viewers/pull/4649))
* fix: Make docker faster and handle PUBLIC_URL properly ([PR#4559](https://github.com/OHIF/Viewers/pull/4559))
* fix: Convert Rows and Columns to numbers before comparison (#4654) ([PR#4656](https://github.com/OHIF/Viewers/pull/4656))
* ui(component): Tooltip component enhanced  ([PR#4635](https://github.com/OHIF/Viewers/pull/4635))
* fix(docker): publish manifest for multiarch and update cs3d ([PR#4650](https://github.com/OHIF/Viewers/pull/4650))
* fix: year 2025 missing in date picker ([PR#4647](https://github.com/OHIF/Viewers/pull/4647))
* feat: Implemented CSV support for Arrow annotation. ([PR#4623](https://github.com/OHIF/Viewers/pull/4623))
* fix(context menu): restrict the context menu accessibility for locked and hidden annotations ([PR#4625](https://github.com/OHIF/Viewers/pull/4625))
* fix(context menu): Implemented closing of context menu on outside click ([PR#4627](https://github.com/OHIF/Viewers/pull/4627))
* fix(3D rendering): disabled light sliders when shade is off ([PR#4631](https://github.com/OHIF/Viewers/pull/4631))
* feat/Added translation option for text 'segment' ([PR#4624](https://github.com/OHIF/Viewers/pull/4624))
* [OHI-1479] fix(segmentation): black preview when loading a seg, and crash on opening panel ([PR#4602](https://github.com/OHIF/Viewers/pull/4602))
* feat(measurements): Provide for the Load (SR) measurements button to optionally clear existing measurements prior to loading the SR. ([PR#4586](https://github.com/OHIF/Viewers/pull/4586))
* fix ohif icons in Header ([PR#4611](https://github.com/OHIF/Viewers/pull/4611))
* fix(worklist): selected patient changes randomly when clicked.  ([PR#4592](https://github.com/OHIF/Viewers/pull/4592))
* docs(azure): Add azure integration guide and datasource configuration ([PR#4579](https://github.com/OHIF/Viewers/pull/4579))
* fix(touch): For viewport interactions use onPointerDown. ([PR#4572](https://github.com/OHIF/Viewers/pull/4572))
* fix(defaultRouteInit): fixes 'madeInClient' parameter when 'makeDisplaySets' is called ([PR#4571](https://github.com/OHIF/Viewers/pull/4571))
* feat(extension): added 'extensionManager' to 'onModeEnter' parameter list ([PR#4569](https://github.com/OHIF/Viewers/pull/4569))
* fix(WorkflowSteps): fixed how hooks are invoked + added support for 'onExit' hook ([PR#4568](https://github.com/OHIF/Viewers/pull/4568))
* fix(cli): publish 4D preclincial mode on NPM so it can be used in the OHIF cli commands ([PR#4557](https://github.com/OHIF/Viewers/pull/4557))
* fix(modes): don't attempt to retrieve a stage index if HPs are an array ([PR#4542](https://github.com/OHIF/Viewers/pull/4542))
* fix: Make the commands ordering the registration order of hte mode ([PR#4492](https://github.com/OHIF/Viewers/pull/4492))
* fix(error-boundray): prevent stack trace from overflowing and make it scrollable ([PR#4541](https://github.com/OHIF/Viewers/pull/4541))
`;

function ReleaseNotes() {
  return (
    <Page>
      <SEO title="OHIF v3.10 | OHIF" />
      <Section className="mx-auto">
        <Title title="OHIF Viewer v3.10" secondary="April 9, 2025" />

        <div className="mt-10">
          <Header />
        </div>
        <div className="flex flex-col space-y-32 mt-12 ">
          <div>
            <div id="overview">
              <SubTitle title={"Overview Video"} />
              <VideoView src="https://www.youtube.com/embed/q8wMPpIMEjU?si=jOJzZFyGd_0S9Vja" />
            </div>
          </div>
          <div id="new-features">
            <SubTitle title={"New Features"} />
            <div className="space-y-32">
              {/* Local AI Segmentation */}
              {/* Labelmap Assist */}
              <Feature
                position="right"
                title="Labelmap Assist"
                imageSrcs={[labelmapAssist]}
              >
                <p>
                  Quickly extend an existing segmentation to the next / previous
                  slice using the Segment Anything (SAM) model. After
                  propagation, you can fine-tune the result with other tools in
                  the segmentation suite.
                </p>
              </Feature>

              {/* Prompt-Based Segmentation */}
              <Feature
                position="left"
                title="Prompt-Based Segmentation"
                imageSrcs={[promptSegmentation]}
              >
                <p>
                  Guide segmentation by placing include or exclude markers. The
                  AI uses these hints to generate precise regions, helping speed
                  up the process while keeping you in control.
                </p>
                <p>
                  These tools are designed to make segmentation faster and more
                  efficient. Performance depends on your machine's hardware, so
                  results may vary based on available resources. Let us know how
                  they work for you.
                </p>
              </Feature>

              {/* One Click GPU Segmentation */}
              <Feature
                position="right"
                title="One Click GPU-Accelerated 3D Segmentation"
                imageSrcs={[oneClickSeg]}
              >
                <p>
                  We are enhancing our segmentation suite with a new
                  WebGPU-accelerated 3D grow cut tool. It runs entirely in the
                  browser, detects segmentable regions in a single interaction,
                  and provides real-time visual feedback for precise selection.
                  To the best of our knowledge, it's the first ever WebGPU
                  implementation of the GrowCut algorithm in the world!
                </p>
              </Feature>

              {/* Brush Preview & Border Hover */}
              <Feature
                position="left"
                title="Brush Preview & Border Hover for Segments"
                imageSrcs={[brushPreview]}
              >
                <p>
                  We have added many improved quality of life and utility
                  functions to the segmentation suite. First, the user may now
                  create a segmentation in preview mode. The preview can be
                  accepted, edited, or rejected. This tool can be combined with
                  manual tools such as the brush, or integrated into automated
                  workflows to allow opportunity for human verification of
                  results.
                </p>
                <p>
                  We have added the ability to highlight a segment when hovering
                  on the border, making it easier to interact with data
                  containing large amounts of segments or many small regions of
                  interest.
                </p>
              </Feature>

              {/* Undo & Redo */}
              <Feature
                position="right"
                title="Undo & Redo Any Viewport Action"
                imageSrcs={[undoRedo]}
              >
                <p>
                  A highly requested feature is now arriving! Now any action in
                  the viewport can be undone or redone with a simple button
                  click or hotkey press. This includes segmentation tools,
                  measurements, and other user inputted overlays in a viewport.
                </p>
              </Feature>

              {/* Segment Statistics */}
              <Feature
                position="left"
                title="View Detailed Segment Statistics"
                imageSrcs={[segmentStats]}
              >
                <p>
                  With 3.10, segment statistics are now automatically calculated
                  and updated as you draw or modify segmentations. For each
                  segment, OHIF computes values like volume, min/max voxel
                  intensity, centroid location, and advanced PET-specific
                  metrics such as SUVpeak. All processing runs smoothly in the
                  background via Web Workers—no lag, no frozen UI. This was one
                  of the most requested features, and it's designed to just work
                  without getting in your way.
                </p>
              </Feature>

              {/* Bidirectional Measurement */}
              <Feature
                position="right"
                title="Generate Bidirectional Measurement for Segments"
              >
                <p>
                  This new tool will automatically detect and create a
                  bidirectional measurement in the largest area of a segment.
                  This tool searches across all slices to find the largest
                  bidirectional measurement, calculates it, and adds it directly
                  to the viewport.
                </p>
              </Feature>

              {/* Labelmap Interpolation */}
              <Feature
                position="left"
                title="Automatic Labelmap Slice Interpolation"
                imageSrcs={[interpolation]}
              >
                <p>
                  We have added a new labelmap interpolation utility that fills
                  in skipped slices between segmentations automatically. It uses
                  morphological priors to generate smooth, consistent results
                  across any plane. The process runs in the background, so you
                  can keep working without interruption.
                </p>
              </Feature>

              {/* Component Library */}
              <Feature
                position="right"
                title="Component Library & Migration"
                imageSrcs={[newComponentLib]}
              >
                <p>
                  We have made significant improvements to our UI component
                  library with a focus on improved access, better functionality,
                  and reduced implementation time. Our components have been
                  repolished and migrated to a new library - @ohif/ui-next. This
                  library improves the organization and presentation of UI
                  components, including an interactive showcase that
                  demonstrates components in action without needing to build the
                  viewer. This allows users to make more informed choices and
                  spend less time during OHIF implementation. The viewer section
                  is now fully migrated to our new library—no legacy components
                  remain. Next up: migrating the worklist.
                </p>
              </Feature>
            </div>
          </div>
          <div id="all-changes"></div>
        </div>
        <div>
          <SubTitle title={"All Changes"} />

          <div className="text-white">
            <MarkdownRenderer text={ChangeLog} />
          </div>
        </div>
      </Section>
    </Page>
  );
}

export default ReleaseNotes;
